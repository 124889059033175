import { Rect, Vertex, RotatedRect } from './types'

export type Drawable = HTMLImageElement | HTMLCanvasElement

export function imageFromBlob (blob: Blob) {
  return imageFromUrl(URL.createObjectURL(blob))
}

export function imageFromUrl (url: string) {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.src = url
  })
}

export function blobFromDrawable (drawable: Drawable) {
  let canvas: HTMLCanvasElement

  if (drawable instanceof HTMLCanvasElement) {
    canvas = drawable
  } else {
    canvas = document.createElement('canvas')

    canvas.width = drawable.width
    canvas.height = drawable.height

    const ctx = canvas.getContext('2d')!
    ctx.drawImage(drawable, 0, 0, drawable.width, drawable.height)
  }

  return new Promise<Blob>((resolve) => {
    canvas.toBlob(b => resolve(b!), 'image/jpeg', 1)
  })
}

export async function imageFromDrawable (drawable: Drawable): Promise<HTMLImageElement> {
  if (drawable instanceof HTMLImageElement) {
    return drawable
  }

  return imageFromBlob(await blobFromDrawable(drawable))
}

export async function urlForDrawable (drawable: Drawable) {
  const blob = await blobFromDrawable(drawable)

  return URL.createObjectURL(blob)
}

export function dataUrlFromBlob (blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result as string))
    reader.readAsDataURL(blob)
  })
}

export async function dataUrlFromUrl (url:string) {
  return dataUrlFromBlob(await blobFromDrawable(await imageFromUrl(url)))
}

export function resizeDrawable (input: Drawable, width: number, height?: number) {
  height = typeof height === 'number' ? height : width * (input.height / input.width)

  const canvas = document.createElement('canvas')

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')!
  ctx.drawImage(input, 0, 0, width, height)

  return canvas
}

export function canvasFromImageData (imageData: ImageData) {
  const canvas = document.createElement('canvas')

  canvas.width = imageData.width
  canvas.height = imageData.height

  const ctx = canvas.getContext('2d')!
  ctx.putImageData(imageData, 0, 0)

  return canvas
}

export async function imageFromImageData (imageData: ImageData) {
  const canvas = canvasFromImageData(imageData)

  const blob = await new Promise<Blob>((resolve) => {
    canvas.toBlob(b => resolve(b!), 'image/jpeg', 1)
  })

  return imageFromBlob(blob)
}

export function getRotatedRect (vertices: Vertex[]): RotatedRect {
  const [a, b, c] = vertices

  const width = Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2)
  const height = Math.sqrt((b.x - c.x) ** 2 + (b.y - c.y) ** 2)

  const angle = getAngle(vertices)

  const x = (a.x + c.x) / 2
  const y = (a.y + c.y) / 2

  return { width, height, angle, x, y }
}

export function pixelOnLine (x: number, y: number, x2: number, y2: number, cb: (x: number, y: number) => boolean) {
  const w = x2 - x
  const h = y2 - y
  let dx1 = 0; let dy1 = 0; let dx2 = 0; let dy2 = 0

  if (w < 0) { dx1 = -1 } else if (w > 0) { dx1 = 1 }
  if (h < 0) { dy1 = -1 } else if (h > 0) { dy1 = 1 }
  if (w < 0) { dx2 = -1 } else if (w > 0) { dx2 = 1 }

  let longest = Math.abs(w)
  let shortest = Math.abs(h)

  if (!(longest > shortest)) {
    longest = Math.abs(h)
    shortest = Math.abs(w)
    if (h < 0) { dy2 = -1 } else if (h > 0) { dy2 = 1 }
    dx2 = 0
  }

  let numerator = longest >> 1

  for (let i = 0; i <= longest; i++) {
    if (!cb(x, y)) { return false }

    numerator += shortest
    if (!(numerator < longest)) {
      numerator -= longest
      x += dx1
      y += dy1
    } else {
      x += dx2
      y += dy2
    }
  }

  return true
}

function rotate (cx: number, cy: number, x: number, y: number, angle: number) {
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)
  const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx
  const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy
  return [nx, ny]
}

export function getAlignedRect (rotatedRect: RotatedRect): Rect {
  const [ax, ay] = rotate(
    rotatedRect.x, rotatedRect.y,
    rotatedRect.x - rotatedRect.width / 2,
    rotatedRect.y - rotatedRect.height / 2,
    rotatedRect.angle
  )

  const [bx, by] = rotate(
    rotatedRect.x, rotatedRect.y,
    rotatedRect.x + rotatedRect.width / 2,
    rotatedRect.y - rotatedRect.height / 2,
    rotatedRect.angle
  )

  const [cx, cy] = rotate(
    rotatedRect.x, rotatedRect.y,
    rotatedRect.x + rotatedRect.width / 2,
    rotatedRect.y + rotatedRect.height / 2,
    rotatedRect.angle
  )

  const [dx, dy] = rotate(
    rotatedRect.x, rotatedRect.y,
    rotatedRect.x - rotatedRect.width / 2,
    rotatedRect.y + rotatedRect.height / 2,
    rotatedRect.angle
  )

  const vertices: { x: number, y: number }[] = [{ x: ax, y: ay }, { x: bx, y: by }, { x: cx, y: cy }, { x: dx, y: dy }]

  let minX = vertices[0].x
  let maxX = vertices[0].x

  let minY = vertices[0].y
  let maxY = vertices[0].y

  for (const vertex of vertices.slice(1)) {
    if (vertex.x < minX) { minX = vertex.x }
    if (vertex.x > maxX) { maxX = vertex.x }

    if (vertex.y < minY) { minY = vertex.y }
    if (vertex.y > maxY) { maxY = vertex.y }
  }

  return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
}

// export function getAlignedRect (vertices: Vertex[]): Rect {
//   let minX = vertices[0].x
//   let maxX = vertices[0].x

//   let minY = vertices[0].y
//   let maxY = vertices[0].y

//   for (const vertex of vertices.slice(1)) {
//     if (vertex.x < minX) { minX = vertex.x }
//     if (vertex.x > maxX) { maxX = vertex.x }

//     if (vertex.y < minY) { minY = vertex.y }
//     if (vertex.y > maxY) { maxY = vertex.y }
//   }

//   return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
// }

/** Uses only the first two vertices (top line) */
export function getAngle (vertices: Vertex[]) {
  const [a, b] = vertices

  return Math.atan((b.y - a.y) / (b.x - a.x))
}

export function cropImage (image: Drawable, rect: Rect) {
  const canvas = document.createElement('canvas')

  canvas.width = image.width
  canvas.height = image.height

  const ctx = canvas.getContext('2d')!

  ctx.drawImage(image, 0, 0)

  const imageData = ctx.getImageData(rect.x, rect.y, rect.width, rect.height)

  return canvasFromImageData(imageData)
}

export function correctRotation (rotatedRect: RotatedRect, image: HTMLCanvasElement) {
  const alignedRect = getAlignedRect(rotatedRect)
  const angle = rotatedRect.angle

  const canvas = document.createElement('canvas')

  canvas.width = rotatedRect.width
  canvas.height = rotatedRect.height

  const ctx = canvas.getContext('2d')!

  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate(-angle)

  ctx.drawImage(image, -alignedRect.width / 2, -alignedRect.height / 2)

  return ctx.canvas
}
