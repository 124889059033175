import { google } from '@google-cloud/vision/build/protos/protos'
import { fabric } from 'fabric'
import { Api } from '@/edshed-common/api'
import { RotatedRect, WritingPiece, WritingPieceWord } from '@/edshed-common/api/types'
import { blobFromDrawable, correctRotation, cropImage, dataUrlFromUrl, Drawable, getAlignedRect, getRotatedRect, imageFromBlob, imageFromUrl, resizeDrawable, urlForDrawable } from '@/utils/drawing'

export interface LoadedWritingPiece {
  id?: number
  owner_id: number
  title: string
  text: string
  __dirty: boolean
  __image: string
  words: LoadedWritingPieceWord[]
  deletedWords?: readonly number[]
}

export interface LoadedWritingPieceWord {
  id?: number
  idx: number
  written_text: string
  correct_text: string
  rect: RotatedRect
  __dirty: boolean
  __image: string | null
  /** Initialised by the view */
  __rect: fabric.Rect | null
}

export async function regenerateWordImage (writingPiece: LoadedWritingPiece, word: LoadedWritingPieceWord) {
  const alignedRect = getAlignedRect(word.rect)

  const image = await imageFromUrl(writingPiece.__image)

  const wordImage = cropImage(image, alignedRect)
  const wordImageCorrected = correctRotation(word.rect, wordImage)
  const wordImageUrl = await urlForDrawable(wordImageCorrected)

  word.__image = wordImageUrl
  word.__dirty = true
}

export async function newWritingPiece (owner_id : number, blob: Blob): Promise<LoadedWritingPiece> {
  // Resize to 1024 x whatever
  let image: Drawable = await imageFromBlob(blob)

  image = await resizeDrawable(image, 1024)
  blob = await blobFromDrawable(image)

  const data: google.cloud.vision.v1.IAnnotateImageResponse = await Api.processWritingPiece(blob)

  const writingPiece: LoadedWritingPiece = {
    owner_id,
    title: 'New import',
    text: '',
    words: [],
    __dirty: true,
    __image: await urlForDrawable(image)
  }

  let index = 0

  for (const text of data.textAnnotations!.slice(1)) {
    const vertices = text.boundingPoly!.vertices!.map(({ x, y }) => ({ x: x!, y: y! }))

    const rect = getRotatedRect(vertices)

    const word: LoadedWritingPieceWord = {
      idx: index,
      written_text: text.description!,
      correct_text: text.description!,
      rect,
      __dirty: true,
      __rect: null,
      __image: null
    }

    regenerateWordImage(writingPiece, word)

    writingPiece.words.push(word)

    index++
  }

  computeText(writingPiece)

  writingPiece.title = writingPiece.text.slice(0, 50)

  return writingPiece
}

export async function loadWritingPiece (id: number): Promise<LoadedWritingPiece> {
  const writingPiece = await Api.loadWritingPiece(id)

  return toLoaded(writingPiece)
}

export async function saveWritingPiece (loadedWritingPiece: LoadedWritingPiece): Promise<LoadedWritingPiece> {
  let writingPiece = await fromLoaded(loadedWritingPiece)

  writingPiece = await Api.saveWritingPiece(writingPiece)

  return toLoaded(writingPiece)
}

function toLoaded (writingPiece: WritingPiece): LoadedWritingPiece {
  const words = writingPiece.words.map((word): LoadedWritingPieceWord => ({
    id: word.id,
    idx: word.idx,
    written_text: word.written_text,
    correct_text: word.correct_text,
    rect: word.rect,
    __dirty: false,
    __image: word.image!.fullSizePath!,
    __rect: null
  }))

  return {
    id: writingPiece.id,
    owner_id: writingPiece.owner_id,
    title: writingPiece.title,
    text: writingPiece.text,
    __dirty: false,
    __image: writingPiece.image!.fullSizePath!,
    words,
    deletedWords: []
  }
}

async function fromLoaded (loaded: LoadedWritingPiece): Promise<WritingPiece> {
  const words = await Promise.all(loaded.words.map(async (word): Promise<WritingPieceWord> => ({
    id: word.id,
    idx: word.idx,
    written_text: word.written_text,
    correct_text: word.correct_text,
    rect: word.rect,
    new_image: word.__dirty ? {
      data: await dataUrlFromUrl(word.__image!),
      extname: '.jpg'
    } : undefined
  })))

  return {
    id: loaded.id,
    owner_id: loaded.owner_id,
    title: loaded.title,
    text: loaded.text,
    new_image: loaded.__dirty ? {
      data: await dataUrlFromUrl(loaded.__image),
      extname: '.jpg'
    } : undefined,
    words,
    deletedWords: loaded.deletedWords
  }
}

export function isWord (word: string) {
  return /^[a-z0-9]+$/i.test(word[0])
}

export function computeText (loaded: LoadedWritingPiece) {
  loaded.text = loaded.words[0].correct_text

  for (const word of loaded.words.slice(1)) {
    if (word.correct_text.length === 0) { continue }

    if (isWord(word.correct_text)) {
      loaded.text += ' ' + word.correct_text
    } else {
      loaded.text += word.correct_text
    }
  }
}

export function reindexWritingPiece (loaded: LoadedWritingPiece) {
  loaded.words.forEach((word, index) => {
    word.idx = index
  })
}

export function removeWords (writingPiece: LoadedWritingPiece, wordIndices: readonly number[]) {
  const removedWords = writingPiece.words.filter(w => wordIndices.includes(w.idx))
  writingPiece.words = writingPiece.words.filter(w => !wordIndices.includes(w.idx))

  writingPiece.deletedWords = [
    ...writingPiece.deletedWords || [],
    ...removedWords.map(({ id }) => id).filter((id): id is number => !!id)
  ]

  reindexWritingPiece(writingPiece)

  return removedWords
}
